import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Puff } from "react-loader-spinner";
import { Link } from "react-router-dom";
import Lookbook2 from "../../assets/media/about/modal2.jpeg";
import Lookbook1 from "../../assets/media/about/modal1.jpeg";
import Lookbook3 from "../../assets/media/about/modal3.jpeg";
import Intro2 from "../../assets/media/banner/intro-2.jpg";
import {
  default as gallery1,
  default as galleryThumb1,
} from "../../assets/media/gallery/1.jpg";
import {
  default as gallery2,
  default as galleryThumb2,
} from "../../assets/media/gallery/2.jpg";
import {
  default as gallery3,
  default as galleryThumb3,
} from "../../assets/media/gallery/3.jpg";
import {
  default as gallery4,
  default as galleryThumb4,
} from "../../assets/media/gallery/4.jpg";
import {
  default as gallery5,
  default as galleryThumb5,
} from "../../assets/media/gallery/5.jpg";
import aboutimg from "../../assets/media/logo/ziya-&-ms-logo.png";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import NewsletterPopup from "../../components/NewsletterPopup";
import SliderPart from "../../components/SliderPart";
import StoreLocater from "../../components/StoreLocator/StoreLocater";
import ProductDetailsNewPopup from "../modal/ProductDetialsNewPopup";

const HomePage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);

  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        // Show the button when the user scrolls down 200 pixels
        setShowBackToTopButton(true);
      } else {
        // Hide the button when the user scrolls back to the top
        setShowBackToTopButton(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const showSlide = (slideNumber) => {
    setCurrentSlide(slideNumber);
  };
  const nextSlide = () => {
    setCurrentSlide((currentSlide % 5) + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 1 ? 5 : currentSlide - 1);
  };
  const galleryImages = [gallery1, gallery2, gallery3, gallery4, gallery5];
  return (
    <div>
      <Helmet>
        <title>
          Buy Latest Gold Mangalsutra Designs Online | Ziyamangalsutra
        </title>
        <meta
          name="description"
          content="Explore a stunning collection of Gold Mangalsutra for women online at Ziya Mangalsutra Gold & Diamonds. Discover the latest designs mangalsutra at best price."
        />
        <link rel="canonical" href="https://ziyamangalsutra.com/" />

        <meta
          name="keywords"
          content="long mangalsutra designs gold, diamond mangalsutra design"
        />
        {/* <!----======= Seo Meta  =======----> */}
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="author" content="" />
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Explore a stunning collection of Gold Mangalsutra for women online at Ziya Mangalsutra Gold & Diamonds. Discover the latest designs mangalsutra at best price."
        />
        <meta
          name="og:title"
          content="Buy Latest Gold Mangalsutra Designs Online | Ziyamangalsutra"
        />
        <meta
          name="og:description"
          content="Invest in your future with our reliable rainwater harvesting & filter systems. Conserve water, reduce bills. Contact us for affordable rainwater solution."
        />
        <meta
          name="og:image"
          content="https://ziyamangalsutra.com/static/media/model-three.faefc9dd0087ab0e6cfb.jpg"
        />
        <meta name="og:url" content="https://ziyamangalsutra.com/" />
        <meta name="og:site_name" content="Ziya Mangalsutra" />
        <meta name="og:locale" content="en_IN" />
        <meta name="og:video" content="" />
        <meta name="og:type" content="website" />
        <meta
          name="twitter:title"
          content="Buy Latest Gold Mangalsutra Designs Online | Ziyamangalsutra"
        />
        <meta
          name="twitter:description"
          content="Invest in your future with our reliable rainwater harvesting & filter systems. Conserve water, reduce bills. Contact us for affordable rainwater solution."
        />
        <meta
          name="twitter:image"
          content="https://ziyamangalsutra.com/static/media/LogoNew.6f3b49725e00df4eef21.png"
        />
        <meta name="twitter:site" content="@ziyamangalsutra" />
        <meta name="twitter:creator" content="@ziyamangalsutra" />
        <script
          type="application/ld+json"
          className="yoast-schema-graph yoast-schema-graph--main"
        >
          {`{
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebSite",
                "@id": "https://ziyamangalsutra.com/#webpage",
                "url": "https://ziyamangalsutra.com/",
                "name": "Ziya Mangalsutra",
                "description": "Explore a stunning collection of Gold Mangalsutra for women online at Ziya Mangalsutra Gold & Diamonds. Discover the latest designs mangalsutra at best price."
              },
              {
                "@type": "WebPage",
                "@id": "https://ziyamangalsutra.com/#webpage",
                "url": "https://ziyamangalsutra.com/",
                "inLanguage": "en-US",
                "name": "Ziya Mangalsutra",
                "isPartOf": {
                  "@id": "https://ziyamangalsutra.com/"
                },
                "datePublished": "2017-09-20T09:19:08+00:00",
                "dateModified": "2020-11-25T06:40:11+00:00",
                "description": "Explore a stunning collection of Gold Mangalsutra for women online at Ziya Mangalsutra Gold & Diamonds. Discover the latest designs mangalsutra at best price."
              }
            ]
          }`}
        </script>
        <script type="application/ld+json">
          {`
        {
        "@context": "http://www.schema.org",
        "@type": "product",
        "name": "Ziya Mangalsutra ",
        "image": "https://ziyamangalsutra.com/static/media/LogoNew.6f3b49725e00df4eef21.png",
        "sku": "IF",
        "mpn": "IF",
        "description": "Explore a stunning collection of Gold Mangalsutra for women online at Ziya Mangalsutra Gold & Diamonds. Discover the latest designs mangalsutra at best price.",
        "aggregateRating": {"@type": "aggregateRating", "ratingValue": "5", "reviewCount": "1", "bestRating":"5 " }
        } `}
        </script>
        <script type="application/ld+json">
          {`
        {
        "@context": "https://schema.org/",
        "@type": "Person",
        "name": "Mr Chetan Thadeshwar",
        "url": "https://ziyamangalsutra.com/",
        "image": "https://ziyamangalsutra.com/static/media/web-4.ace970ac8ad086acaa49.jpg",
        "jobTitle": "Proprietor",
        "worksFor": {
        "@type": "Organization",
        "name": "Ziya Mangalsutra"
        }
      }
        `}
        </script>
        <script type="application/ld+json">
          {`
          {
        "@context": "http://www.schema.org",
        "@type": "Organization",
        "name": "Ziya Mangalsutra",
        "url": "https://ziyamangalsutra.com/",
        "logo": "https://ziyamangalsutra.com/static/media/LogoNew.6f3b49725e00df4eef21.png",
        "image": "https://ziyamangalsutra.com/static/media/model-three.faefc9dd0087ab0e6cfb.jpg",
        "description": "Explore a stunning collection of Gold Mangalsutra for women online at Ziya Mangalsutra Gold & Diamonds. Discover the latest designs mangalsutra at best price.",
        "address": {
        "@type": "PostalAddress",
        "streetAddress": "Cotton Exchange Building, B-1, Jewel World, Kalbadevi Rd, opp. Surati hotel, Mumbai, Maharashtra - 400002",
        "addressLocality": "Surati hotel",
        "addressRegion": "Mumbai",
        "addressCountry": "India"
        },
        "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+022 43111222"
        }
        }
        `}
        </script>
      </Helmet>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#000"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <Header />

          {/* <NewsletterPopup /> */}
          <ProductDetailsNewPopup />
          <div id="site-main" className="site-main">
            <div id="main-content" className="main-content">
              <div id="primary" className="content-area">
                <div id="content" className="site-content" role="main">
                  <SliderPart />
                  <section className="section section-padding m-b-70">
                    <div className="section-container">
                      <div className="block block-intro layout-2 text-center">
                        <div className="block-widget-wrap">
                          <div className="intro-image animation-horizontal">
                            <img width={170} height={170} src={aboutimg} alt />
                          </div>

                          <div className="intro-text">
                            Driven by a blend of design innovation, timeless
                            tradition, and contemporary aesthetics, ZIYA
                            presents a mangalsutra collection that meets the
                            evolving styles and preferences of today's empowered
                            women.
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="section section-padding m-b-80">
                    <div className="section-container">
                      {/* Block Lookbook */}
                      <div className="block block-lookbook">
                        <div className="block-widget-wrap">
                          <div className="lookbook-item">
                            <div className="row">
                              <div className="col-md-6 col-image">
                                <div className="lookbook-container">
                                  <div className="lookbook-content">
                                    <div className="item">
                                      <img
                                        width={690}
                                        height={473}
                                        src={Lookbook1}
                                        alt="Look Book 1"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-text">
                                <div className="lookbook-intro-wrap position-center text-center">
                                  <div className="lookbook-intro">
                                    <h2 className="title">Bridal classic</h2>
                                    <div className="description">
                                      Brighten up your look with vibrant
                                      gemstone jewelry.
                                    </div>
                                    {/* <Link
                                      to="/product-details/65360840af0c97d97d5428c3"
                                      className="button button-primary animation-horizontal"
                                    >
                                      SHOP NOW
                                    </Link> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="lookbook-item">
                            <div className="row">
                              <div className="col-md-6 col-text">
                                <div className="lookbook-intro-wrap position-center text-center">
                                  <div className="lookbook-intro">
                                    <h2 className="title">Modern collection</h2>
                                    <div className="description">
                                      Introducing your outfit’s missing link.
                                    </div>
                                    {/* <Link
                                      to="/product-details/6581653748269108e549a654"
                                      className="button button-primary animation-horizontal"
                                    >
                                      SHOP NOW
                                    </Link> */}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-image">
                                <div className="lookbook-container">
                                  <div className="lookbook-content">
                                    <div className="item">
                                      <img
                                        width={690}
                                        height={473}
                                        src={Lookbook2}
                                        alt="Look Book 1"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="lookbook-item">
                            <div className="row">
                              <div className="col-md-6 col-image">
                                <div className="lookbook-container">
                                  <div className="lookbook-content">
                                    <div className="item">
                                      <img
                                        width={690}
                                        height={473}
                                        src={Lookbook3}
                                        alt="Look Book 1"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-text">
                                <div className="lookbook-intro-wrap position-center text-center">
                                  <div className="lookbook-intro">
                                    <h2 className="title">Padmavat</h2>
                                    <div className="description">
                                      Brighten up your look with vibrant
                                      gemstone jewelry.
                                    </div>
                                    {/* <Link
                                      to="/product-details/6581655b48269108e549a65f"
                                      className="button button-primary animation-horizontal"
                                    >
                                      SHOP NOW
                                    </Link> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div className="section section-padding background-img bg-img-3 p-t-60 p-b-70 m-b-70">
                    {/* <TrendingProducts /> */}
                  </div>
                  <section className="section section-padding m-b-70">
                    <div className="section-container">
                      {/* Block Intro (Layout 3) */}
                      <div className="block block-intro layout-3">
                        <div className="block-widget-wrap">
                          <div className="row">
                            <div className="section-column left">
                              <div className="intro-wrap">
                                <h2 className="intro-title">
                                  ZIYA offers <br />
                                  exquisitely crafted mangalsutras
                                </h2>
                                <div className="intro-item">
                                  ZIYA offers exquisitely crafted mangalsutras
                                  designed to cater to the refined preferences
                                  of contemporary women. As a flagship Brand
                                  from Shringar, ZIYA carries a rich heritage of
                                  more than six decades of exceptional
                                  craftsmanship and unwavering commitment to
                                  enduring quality. These timeless pieces are
                                  designed to last a lifetime, symbolizing a
                                  blend of tradition and contemporary elegance.{" "}
                                </div>
                                <div className="intro-item">
                                  With a keen focus on enhancing your jewelry
                                  collection, Shringar takes immense pride in
                                  presenting ZIYA, its most opulent collection,
                                  exclusively crafted to elevate your style.
                                </div>
                                <div className="intro-item">
                                  <p>A promise of Togetherness</p>
                                </div>
                                <div className="intro-btn">
                                  <Link
                                    to="/ziya-experience"
                                    className="button button-outline animation-horizontal"
                                  >
                                    Read more
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="section-column right animation-horizontal hover-opacity">
                              <img
                                width={690}
                                height={498}
                                src={Intro2}
                                alt="intro"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="container-fluid" id="gallery-section">
                    <Row className="trendingImgRow">
                      <Col md="6" className="column">
                        <img
                          src={galleryThumb1}
                          onClick={() => {
                            openModal();
                            showSlide(1);
                          }}
                          className="hover-shadow cursor"
                          alt="Image 1"
                        />
                      </Col>
                      <Col md="6" className="column">
                        <img
                          src={galleryThumb2}
                          onClick={() => {
                            openModal();
                            showSlide(2);
                          }}
                          className="hover-shadow cursor"
                          alt="Image 2"
                        />
                      </Col>
                      <Col md="6" className="column">
                        <img
                          src={galleryThumb3}
                          onClick={() => {
                            openModal();
                            showSlide(3);
                          }}
                          className="hover-shadow cursor"
                          alt="Image 3"
                        />
                      </Col>
                      <Col md="6" className="column">
                        <img
                          src={galleryThumb4}
                          onClick={() => {
                            openModal();
                            showSlide(4);
                          }}
                          className="hover-shadow cursor"
                          alt="Image 4"
                        />
                      </Col>
                      <Col md="6" className="column">
                        <img
                          src={galleryThumb5}
                          onClick={() => {
                            openModal();
                            showSlide(5);
                          }}
                          className="hover-shadow cursor"
                          alt="Image 5"
                        />
                      </Col>
                      {/* Add more images with similar click handlers */}
                    </Row>
                    {modalOpen && (
                      <div id="myModal" className="modal-gal">
                        <span className="close cursor" onClick={closeModal}>
                          ×
                        </span>
                        <div className="modal-content">
                          <div className="mySlides">
                            <div className="numbertext">{currentSlide} / 5</div>
                            <img
                              src={galleryImages[currentSlide - 1]}
                              alt={`Image ${currentSlide}`}
                            />
                          </div>
                          {/* Add more modal slides here */}
                          <div className="row gallery-scroll">
                            <div className="columnSlider">
                              <img
                                className={`demo cursor ${
                                  currentSlide === 1 ? "active" : ""
                                }`}
                                src={galleryThumb1}
                                onClick={() => showSlide(1)}
                                alt="Thumbnail 1"
                              />
                            </div>
                            <div className="columnSlider">
                              <img
                                className={`demo cursor ${
                                  currentSlide === 2 ? "active" : ""
                                }`}
                                src={galleryThumb2}
                                onClick={() => showSlide(2)}
                                alt="Thumbnail 2"
                              />
                            </div>
                            <div className="columnSlider">
                              <img
                                className={`demo cursor ${
                                  currentSlide === 3 ? "active" : ""
                                }`}
                                src={galleryThumb3}
                                onClick={() => showSlide(3)}
                                alt="Thumbnail 3"
                              />
                            </div>
                            <div className="columnSlider">
                              <img
                                className={`demo cursor ${
                                  currentSlide === 4 ? "active" : ""
                                }`}
                                src={galleryThumb4}
                                onClick={() => showSlide(4)}
                                alt="Thumbnail 3"
                              />
                            </div>
                            <div className="columnSlider">
                              <img
                                className={`demo cursor ${
                                  currentSlide === 5 ? "active" : ""
                                }`}
                                src={galleryThumb5}
                                onClick={() => showSlide(5)}
                                alt="Thumbnail 3"
                              />
                            </div>
                            {/* Add more thumbnail images with similar conditionally rendered classes */}
                          </div>
                          <button className="prev" onClick={prevSlide}>
                            ❮
                          </button>
                          <button className="next" onClick={nextSlide}>
                            ❯
                          </button>
                        </div>
                      </div>
                    )}
                  </section>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          {showBackToTopButton && (
            <div className="back-top button-show" onClick={scrollToTop}>
              <i className="arrow_carrot-up" />
            </div>
          )}
        </div>
      )}
      <StoreLocater />
    </div>
  );
};

export default HomePage;

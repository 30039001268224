import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdHeart } from "react-icons/io";
import { Link } from "react-router-dom";
import logo from "../assets/media/logo/ziya-&-ms-logo.png";
import { listLatestGoldPrice } from "../functions/PriceTag/GoldPrice";
import { listProspect } from "../functions/Prospect/Prsopect";
import LoginModal from "../pages/modal/loginModal";
import GoldPriceSection from "./GoldPriceSection";
const Header = () => {
  const [activeLink, setActiveLink] = useState(null);
  const [latestGoldPrice, setLatestGoldPrice] = useState("");
  const [userData, setUserData] = useState("");
  const [modalList, setModalList] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const handleHeartIconClick = () => {
    // Check if user is logged in
    if (userCheck) {
      // If user is logged in, open the LoginModal
      window.location.href = "/whishlistnew";
    } else {
      setModalList(true);
      // setOpenLogin(true);
    }
  };

  const [show, setShow] = useState(false);

  const tog_list = () => {
    setShow(!show);
    setModalList(true);
    // setValues(initialState);
  };

  const [userCheck, setuserCheck] = useState(false);
  let user = localStorage.getItem("RegisterUserID");

  useEffect(() => {
    loadGoldPrice();
    if (user) {
      setuserCheck(true);
      console.log("user found");
    }
  }, []);

  useEffect(() => {
    if (user) {
      setuserCheck(true);
      listProspect().then((res) => {
        console.log("prsopect data", res.data);
        console.log("USER", user);
        const d = res.data;
        const checkData = d.find((l) => l._id === user);
        setUserData(checkData.ContactPersonName);
      });
    }
  }, [user]);

  const loadGoldPrice = () => {
    listLatestGoldPrice().then((res) => {
      console.log("price", res.data);
      setLatestGoldPrice(res.data.GoldPrice);
      console.log("la", latestGoldPrice);
    });
  };
  const handleLogout = () => {
    localStorage.removeItem("RegisterUserID");
    window.location.reload();
  };

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <div id="page" className="hfeed page-wrapper">
        <header id="site-header" className="site-header header-v2">
          <div className="header-mobile">
            <div className="section-padding">
              <div className="section-container large">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-3 header-right d-flex">
                    <div className="navbar-header">
                      <button
                        className="hamberge-btn"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        {" "}
                        ☰
                      </button>
                      <div className={`mobile-sidebar ${isOpen ? "open" : ""}`}>
                        <div className="sidebar-content">
                          {/* Your sidebar content goes here */}
                          <div className="d-flex justify-content-end">
                            <AiOutlineClose onClick={toggleSidebar} />
                          </div>
                          <ul>
                            <li>
                              <Link to="/" style={{ color: "white" }}>
                                HOME
                              </Link>
                            </li>
                            <li>
                              <Link to="/who-we-are" style={{ color: "white" }}>
                                WHO WE ARE
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/ziya-experience"
                                style={{ color: "white" }}
                              >
                                THE ZIYA EXPERIENCE
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/the-collection"
                                style={{ color: "white" }}
                              >
                                THE COLLECTION
                              </Link>
                            </li>
                            {/* <li>
                              <Link
                                to="/find-my-ziya"
                                style={{ color: "white" }}
                              >
                                FIND MY ZIYA
                              </Link>
                            </li> */}
                            <li>
                              <Link
                                to="/ziya-contact"
                                style={{ color: "white" }}
                              >
                                INQUIRY
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 header-center">
                    <div className="site-logo">
                      <Link to="/">
                        <img
                          src={logo}
                          alt="Ziya Jewellery"
                          className="lgoMain"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-3">
                    <div style={{ display: "flex", gap: "15px" }}>
                      <div>
                        <LoginModal />
                        {/* <SignUpIndex
                          modalList={modalList}
                          setModalList={setModalList}
                        /> */}
                      </div>
                      <div>
                        <Link
                          to="#"
                          className="menu-link"
                          onClick={handleHeartIconClick}
                        >
                          <span className="menu-item-text userIcon">
                            <IoMdHeart />
                          </span>
                          {openLogin && (
                            <LoginModal
                              modalList={modalList}
                              setModalList={setModalList}
                            />
                          )}
                        </Link>
                        {/* <Link
                          to={userCheck ? "/whishlistnew" : "/login"}
                          className="menu-link"
                        >
                          <span className="menu-item-text userIcon ">
                            {" "}
                            <FaRegHeart />
                          </span>
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div className="header-desktop">
        <div className="header-wrapper">
          <GoldPriceSection />
          <div className="section-padding">
            <div className="section-container large p-l-r">
              <div className="row justify-content-center">
                <div
                  className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 header-center d-flex align-items-center justify-content-center"
                  style={{ margin: "10px 0", gap: "130px" }}
                >
                  <div className="site-navigation left">
                    <nav id="main-navigation">
                      <ul id="menu-main-menu" className="menu d-flex">
                        <li className="level-0 menu-item" data-page="home">
                          <Link to="/" className="menu-link">
                            <span className="menu-item-text">Home</span>
                          </Link>
                        </li>
                        <li
                          className="level-0 menu-item"
                          data-page="who-we-are"
                        >
                          <Link to="/who-we-are" className="menu-link">
                            <span className="menu-item-text">Who we are</span>
                          </Link>
                        </li>
                        <li
                          className="level-0 menu-item"
                          data-page="ziya-experience"
                        >
                          <Link to="/ziya-experience" className="menu-link">
                            <span className="menu-item-text">
                              The Ziya Experience
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="site-logo">
                    <Link to="/">
                      <img
                        src={logo}
                        alt="Ziya Jewellery"
                        className="lgoMain"
                      />
                    </Link>
                  </div>
                  <div className="site-navigation right">
                    <nav id="main-navigation">
                      <ul id="menu-main-menu" className="menu d-flex">
                        <li
                          className="level-0 menu-item"
                          data-page="the-collection"
                          style={{ marginTop: "15px" }}
                        >
                          <Link to="/the-collection" className="menu-link">
                            <span className="menu-item-text">
                              The Collection
                            </span>
                          </Link>
                        </li>
                        {/* <li
                          className="level-0 menu-item"
                          data-page="find-my-ziya"
                          style={{ marginTop: "15px" }}
                        >
                          <Link to="/find-my-ziya" className="menu-link">
                            <span className="menu-item-text">Find My Ziya</span>
                          </Link>
                        </li> */}
                        <li
                          className="level-0 menu-item"
                          data-page="ziya-contact"
                          style={{ marginTop: "15px" }}
                        >
                          <Link to="/ziya-contact" className="menu-link">
                            <span className="menu-item-text">INQUIRY</span>
                          </Link>
                        </li>

                        {/* <li
                          className="level-0 menu-item"
                          data-page="ziya-contact"
                          style={{ marginTop: "10px" }}
                        >
                          <LoginModal />
                        </li>
                        <li
                          className="level-0 menu-item"
                          data-page="whislist"
                          style={{ marginTop: "10px" }}
                        >
                          <Link
                            to={userCheck ? "/whishlistnew" : "/login"}
                            className="menu-link"
                            style={{ textDecoration: "none" }}
                          >
                            <span className="menu-item-text userIcon">
                              <FaRegHeart />
                            </span>
                          </Link>
                        </li> */}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

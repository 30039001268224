import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/media/logo/ziya-&-ms-logo.png";

const Footer = () => {
  return (
    <div>
      <footer id="site-footer" className="site-footer background">
        <div className="footer">
          <div className="section-padding">
            <div className="section-container">
              <div className="block-widget-wrap">
                <div className="row">
                  <div className="col-lg-5 col-md-6">
                    <div className="block block-menu text-center">
                      <img src={logo} className="footer-logo " />
                      <div className="block-content ">
                        <p className="footer-p">
                          ZIYA offers exquisitely crafted mangalsutras designed
                          to cater to the refined preferences of contemporary
                          women.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 text-start">
                    <div className="block block-menu m-b-20">
                      <h2 className="block-title">Contact Us</h2>
                      <div className="block-content">
                        <ul>
                          <li>
                            <span className="contact-us">Head Office:</span>{" "}
                            Cotton Exchange Building, B-1, Jewel World,
                            Kalbadevi Rd, opp. Surati hotel, Mumbai, Maharashtra
                            400002
                          </li>
                          <li>
                            <span className="contact-us">Tel:</span>{" "}
                            <Link
                              className="footerLinkContact"
                              to="tel:0265-2351355"
                            >
                              022 43111222
                            </Link>
                          </li>
                          <li>
                            <span className="contact-us">Email:</span>{" "}
                            <Link
                              className="footerLinkContact"
                              to="mailto:info@ziyajewels.com"
                            >
                              info@ziyajewels.com
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="block block-social">
                      <ul className="social-link">
                        <li>
                          <Link
                            to="https://instagram.com/ziya_jewels?igshid=NGVhN2U2NjQ0Yg=="
                            target="_blanck"
                          >
                            <i className="fa fa-instagram" />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://www.facebook.com/ziyajewelsredesigningmangalsutra?mibextid=ZbWKwL"
                            target="_blanck"
                          >
                            <i className="fa fa-facebook" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 text-start">
                    <div className="block block-menu">
                      <h2 className="block-title">Quick Links</h2>
                      <div className="block-content">
                        <ul>
                          <li>
                            <Link to="/who-we-are">WHO WE ARE</Link>
                          </li>
                          <li>
                            <Link to="/ziya-experience">
                              THE ZIYA EXPERIENCE
                            </Link>
                          </li>
                          <li>
                            <Link to="/the-collection">THE COLLECTION</Link>
                          </li>
                          {/* <li>
                            <Link to="/find-my-ziya">FIND MY ZIYA</Link>
                          </li> */}
                          <li>
                            <Link to="/ziya-contact">INQUIRY</Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.REACT_APP_API_URL_ZIYA}/uploads/Terms/terms-and-conditions.pdf`}
                              target="_black"
                            >
                              TERMS AND CONDITIONS
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 text-start">
                    <div className="block block-menu">
                      <h2 className="block-title">Catalog</h2>
                      <div className="block-content">
                        <ul>
                          <li>
                            <Link to="/product-details/65360840af0c97d97d5428c3">
                              BRIDAL
                            </Link>
                          </li>
                          <li>
                            <Link to="/product-details/6581655b48269108e549a65f">
                              PADMAVAT
                            </Link>
                          </li>
                          <li>
                            <Link to="/product-details/6581653748269108e549a654">
                              MODERN
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="section-padding">
            <div className="section-container">
              <div className="block-widget-wrap">
                <div className="row">
                  <div className="col-md-6 text-start">
                    <div className="footer-left">
                      <p className="copyright">
                        Copyright © 2023. <Link to="#">ZIYA</Link>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="footer-right">
                      <p className="copyright">
                        Developed By:{" "}
                        <Link to="https://www.barodaweb.com" target="_blanck">
                          Barodaweb: The e-Catalogue Designer
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
